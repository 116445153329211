<template>
  <div class="account">
    <div class="account__column">
      <h4 class="account__column__header">
        {{ $t('account.title-personal') }}
      </h4>
      <el-form
        label-position="top"
        :model="form"
        :rules="$options.rules"
        ref="formRef"
      >
        <el-form-item :label="$t('label.firstname')" prop="firstName">
          <el-input
            v-model="form.firstName"
            :placeholder="$t('label.firstname')"
          />
        </el-form-item>
        <el-form-item :label="$t('label.lastname')" prop="lastName">
          <el-input
            v-model="form.lastName"
            :placeholder="$t('label.lastname')"
          />
        </el-form-item>
        <div class="account__column__checkboxes">
          <el-checkbox class="checkbox-form" v-model="changeEmail">{{
            $t('account.checkbox-email')
          }}</el-checkbox>
          <el-checkbox class="checkbox-form" v-model="changePassword">{{
            $t('account.checkbox-password')
          }}</el-checkbox>
        </div>
        <p class="account__column__title">{{ toggleTitle }}</p>
        <el-form-item
          v-if="changeEmail"
          :label="$t('label.email')"
          prop="email"
          @change="validateEmail"
          :class="{ account__column__error: error.email }"
        >
          <el-input v-model="form.email" :placeholder="$t('label.email')" />
          <p v-if="error.email" class="account__column__error__text">
            {{ $t('error.email') }}
          </p>
        </el-form-item>
        <el-form-item
          v-if="changePassword"
          :label="$t('label.password-current')"
          prop="currentPassword"
        >
          <el-input v-model="form.currentPassword" type="password" />
        </el-form-item>
        <el-form-item
          v-if="changePassword"
          :label="$t('label.password-new')"
          prop="newPassword"
          @change="validateNewPassword"
          :class="{ account__column__error: error.newPassword }"
        >
          <el-input v-model="form.newPassword" />
          <p v-if="error.newPassword" class="account__column__error__text">
            {{ $t('error.password') }}
          </p>
        </el-form-item>
        <el-form-item
          v-if="changePassword"
          :label="$t('label.password-again')"
          prop="newPasswordConfirm"
          @change="validateNewPasswordConfirm"
          :class="{ account__column__error: error.newPasswordConfirm }"
        >
          <el-input v-model="form.newPasswordConfirm" />
          <p
            v-if="error.newPasswordConfirm"
            class="account__column__error__text"
          >
            {{ $t('error.password-confirm') }}
          </p>
        </el-form-item>
        <h4 class="account__column__header">
          {{ $t('account.title-profession') }}
        </h4>
        <el-form-item :label="$t('label.usergroup')">
          <el-input disabled v-model="userRoles">
            <template #suffix>
              <img src="@/assets/images/lock.svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="account__info__country" :label="$t('label.country')">
          <el-input disabled v-model="user.country">
            <template #suffix>
              <img src="@/assets/images/lock.svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="account__info__customer-number" :label="$t('label.customer')">
          <el-input disabled v-model="user.customer_number">
            <template #suffix>
              <img src="@/assets/images/lock.svg" />
            </template>
          </el-input>
        </el-form-item>
        <hr />
        <Button
          class="button--left"
          :label="$t('button.save')"
          :disabled="isButtonDisabled"
          @click="changeUserDetails"
        />
      </el-form>
    </div>
    <div class="account__back">
      <hr />
      <a class="account__back__link" @click="backToMain">{{
        $t('link.back')
      }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElNotification } from 'element-plus'
import Button from '@/components/Button.vue'

export default {
  components: {
    Button,
  },
  rules: {
    firstName: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    lastName: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    currentPassword: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    newPassword: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    newPasswordConfirm: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      error: {
        email: false,
        newPassword: false,
        newPasswordConfirm: false,
      },
      changeEmail: true,
      changePassword: true,
    }
  },
  mounted() {
    this.form.firstName = this.user?.first_name
    this.form.lastName = this.user?.last_name
    this.form.email = this.user?.user_email
  },
  computed: {
    ...mapGetters(['user', 'userUpdated']),
    userRoles() {
      return this.user.roles?.map(role => role.label)
    },
    toggleTitle() {
      if (this.changeEmail && !this.changePassword)
        return this.$t('account.subtitle-email')
      if (!this.changeEmail && this.changePassword)
        return this.$t('account.subtitle-password')
      if (this.changeEmail && this.changePassword)
        return this.$t('account.subtitle-both')
      return ''
    },
    isButtonDisabled() {
      return (
        !this.form.firstName ||
        !this.form.lastName ||
        (this.changeEmail && !this.form.email || this.error.email) ||
        (this.changePassword &&
          (!this.form.currentPassword ||
            !this.form.newPassword ||
            this.error.newPassword ||
            !this.form.newPasswordConfirm ||
            this.error.newPasswordConfirm))
      )
    },
  },
  watch: {
    userUpdated() {
      if (this.userUpdated) {
        ElNotification({
          title: this.$t('notification.changes-saved'),
          position: 'bottom-right',
          duration: 2000,
          type: 'success',
          showClose: false,
        })

        // reset information
        this.form.currentPassword = ''
        this.form.newPassword = ''
        this.form.newPasswordConfirm = ''

        this.$store.commit('setUserUpdated', false)
      }
    },
  },
  methods: {
    validateEmail() {
      if (
        (this.form.email && !this.form.email.includes('@')) ||
        !this.form.email.includes('.')
      ) {
        this.error.email = true
      } else {
        this.error.email = false
      }
    },
    validateNewPassword() {
      if (this.form.newPassword) {
        !this.form.newPassword.match(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        )
          ? (this.error.newPassword = true)
          : (this.error.newPassword = false)
      }
    },
    validateNewPasswordConfirm() {
      if (this.form.newPasswordConfirm) {
        this.form.newPassword !== this.form.newPasswordConfirm
          ? (this.error.newPasswordConfirm = true)
          : (this.error.newPasswordConfirm = false)
      }
    },
    changeUserDetails(e) {
      e.preventDefault()

      this.$refs.formRef.validate((valid) => {
        if (valid) this.$store.dispatch('userUpdate', {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          ...this.changeEmail && {
            email: this.form.email,
          },
          ...this.changePassword && {
            currentPassword: this.form.currentPassword,
            newPassword: this.form.newPassword,
            newPasswordConfirm: this.form.newPasswordConfirm,
          }
        })
      })
    },
    backToMain() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.account {
  @include flex-center-align;
  flex-direction: column;
  position: relative;

  &__column {
    display: flex;
    flex-direction: column;
    padding: 0 20px 80px;
    max-width: 540px;
    width: 100%;

    &__header {
      margin-top: 40px;
      margin-bottom: 40px;

      @include mq($from: mobile) {
        margin-top: 80px;
      }

      &::after {
        content: '';
        background-color: $color-primary;
        display: block;
        margin-top: 7px;
        height: 4px;
        width: 80px;
      }
    }

    &__title {
      font-size: 22px;
      font-weight: $font-weight-medium;
      margin-top: 60px;
      margin-bottom: 24px;
    }

    &__error {
      .el-input__inner {
        border-color: $color-error;
      }

      &__text {
        color: $color-error;
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }
    }

    hr {
      margin: 60px 0 20px 0;
    }

    .button {
      margin-bottom: 80px;
    }

    .button--left {
      align-self: flex-start;
      margin-bottom: 0;
    }
  }

  &__info {
    &__country, &__customer-number {
      .elf & {
        display: none;
      }
    }
  }

  &__back {
    position: absolute;
    right: 20px;
    bottom: 0;
    height: 144px;
    width: calc((100vw - 540px) / 2);

    &__link {
      border-bottom: 2px solid $color-primary;
      cursor: pointer;
      line-height: 32px;
      font-weight: $font-weight-medium;
      float: right;
      margin-top: 28px;
    }
  }
}
</style>
